import {getOutlet} from 'reconnect.js';
import Config from '../../data.json';
import {req} from '../Utils/ApiUtils';

const UserOutlet = getOutlet('user');

async function fetchProductList(query, paging) {
  /*
  const {
    update_time_from = 0,
    update_time_to = 0,
    item_status = ['NORMAL'],
  } = query;
  */
  const {keyword} = query;
  const resp = await req(
    `${Config.apiHost}/momomall/product/list?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        goodsName: keyword,
        // offset: paging.offset,
        // page_size: paging.limit,
        // update_time_from,
        // update_time_to,
        // item_status,
      },
    },
  );

  const {outputObject} = resp;

  return {
    total: outputObject.length,
    results: outputObject.slice(paging.offset, paging.offset + paging.limit),
  };
}

export {fetchProductList};

import React from 'react';
import {message} from 'antd';
import GenericForm from '../../Generic/Form';
import * as MomoMallActions from '../../Actions/MomoMall';
import * as AppActions from '../../AppActions';

const pageSizeOptions = [10, 30, 50];
const sortOptions = [
  {name: '價錢高到低', value: '-price'},
  {name: '價錢低到高', value: 'price'},
];

async function fetchMany(
  {sort, paging, keyword, startDate, endDate, itemStatus},
  onSuccess,
  onFailure,
) {
  if (!keyword) {
    message.warn('Momo-Mall搜尋關鍵字不可為空!');
    onFailure();
    AppActions.setLoading(false);
    return;
  }

  console.log('fetchMany', startDate, endDate);
  try {
    AppActions.setLoading(true);
    const {total, results} = await MomoMallActions.fetchProductList(
      {
        keyword,
        /*
        update_time_from: Math.floor(new Date(startDate).getTime() / 1000),
        update_time_to: Math.floor(new Date(endDate).getTime() / 1000),
        item_status: [itemStatus],
      */
      },
      paging,
    );
    onSuccess({total, results});
  } catch (ex) {
    console.warn('API error', ex);
    onFailure();
  } finally {
    AppActions.setLoading(false);
  }
}

const makeColumns = () => [
  {
    title: 'GOODS_CODE',
    key: 'GOODS_CODE',
    dataIndex: 'goodsCode',
    width: 120,
    fixed: 'left',
  },
  {title: 'GOODS_NAME', key: 'GOODS_NAME', dataIndex: 'goodsName', width: 500},
  {
    title: 'ENTP_GOODS_NO',
    key: 'ENTP_GOODS_NO',
    dataIndex: 'entpGoodsNo',
    width: 300,
  },
  {
    title: 'SALE_PRICE',
    key: 'SALE_PRICE',
    dataIndex: 'salePrice',
    width: 100,
  },
];

const formSpec = {
  schema: {
    title: '',
    type: 'object',
    required: ['name', 'price'],
    properties: {
      item_id: {type: 'string', title: 'id', readOnly: true},
      item_name: {type: 'string', title: '商品名稱'},
      item_status: {type: 'string', title: '商品狀態'},
      category_id: {type: 'string', title: 'category_id'},
      description: {type: 'string', title: 'description'},
    },
  },
  uiSchema: {},
};

function Form(props) {
  const {instance} = props;

  return (
    <div>
      <GenericForm
        schema={formSpec.schema}
        uiSchema={formSpec.uiSchema}
        instance={instance}
        onSubmit={async (formData) => {
          try {
            AppActions.setLoading(true);
            await AppActions.delay(1000);
            console.log('formData', formData);
            message.success('成功!');
          } catch (ex) {
            message.error('API failure');
          } finally {
            AppActions.setLoading(false);
          }
        }}
      />
    </div>
  );
}

export {pageSizeOptions, sortOptions, fetchMany, makeColumns};
